import React from "react";

import { ButtonHTMLAttributes } from "react";
import { DetailedHTMLProps } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Button.module.scss";

/**
 * @type ButtonProps
 */
export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    variant?: string;
};

/**
 * @const Button
 */
const Button = (props: ButtonProps) => {
    const {
        className,
        variant,
        ...restProps
    } = props;

    return (
        <button { ...restProps } className={ clsx(classNames.button, {
            [classNames.green]: variant == "green",
            [classNames.white]: variant == "white"
        }, className) } />
    );
};

export default Button;
