// extracted by mini-css-extract-plugin
export var addButton = "WorkMapView-module--addButton---YUoS";
export var addRowButton = "WorkMapView-module--addRowButton--Q0cGq";
export var addRowButtonWrapper = "WorkMapView-module--addRowButtonWrapper--9t62s";
export var badge = "WorkMapView-module--badge--FJC2J";
export var blue = "WorkMapView-module--blue--LaBW1";
export var body = "WorkMapView-module--body--M9pYU";
export var button = "WorkMapView-module--button--gws1T";
export var buttonWrapper = "WorkMapView-module--buttonWrapper--cw6nW";
export var checkbox = "WorkMapView-module--checkbox--G75Ff";
export var dailyRequests = "WorkMapView-module--dailyRequests--5ac-B";
export var data = "WorkMapView-module--data--O5WHQ";
export var deliveryTime = "WorkMapView-module--deliveryTime--sgAH5";
export var deliveryTimeError = "WorkMapView-module--deliveryTimeError--iLT-8";
export var div = "WorkMapView-module--div--0oGnc";
export var divider = "WorkMapView-module--divider--qp7lS";
export var duplicate = "WorkMapView-module--duplicate--n5Or+";
export var error = "WorkMapView-module--error--MFvDP";
export var errors = "WorkMapView-module--errors--XmP8x";
export var euro = "WorkMapView-module--euro--yRGHQ";
export var fieldset = "WorkMapView-module--fieldset--XwSHG";
export var head = "WorkMapView-module--head--+Va8r";
export var heading = "WorkMapView-module--heading--U9zA2";
export var headingSub = "WorkMapView-module--headingSub--lU1wa";
export var icon = "WorkMapView-module--icon--RPa2c";
export var input = "WorkMapView-module--input--es-Bz";
export var inputWrapper = "WorkMapView-module--inputWrapper--NhdHp";
export var label = "WorkMapView-module--label--HA3QG";
export var labelWrapper = "WorkMapView-module--labelWrapper--d+GQM";
export var list = "WorkMapView-module--list--wAtWq";
export var listItem = "WorkMapView-module--listItem--0kIb0";
export var noWorkAreas = "WorkMapView-module--noWorkAreas--7EO6z";
export var notification = "WorkMapView-module--notification--9IjY0";
export var paragraph = "WorkMapView-module--paragraph--YckbN";
export var remove = "WorkMapView-module--remove--cuOLy";
export var removeButton = "WorkMapView-module--removeButton--4iEQb";
export var requireQuote = "WorkMapView-module--requireQuote--oNSGr";
export var row = "WorkMapView-module--row--PPgKi";
export var suffix = "WorkMapView-module--suffix--8Wf78";
export var table = "WorkMapView-module--table--O14gb";
export var tip = "WorkMapView-module--tip--dDlrQ";
export var workArea = "WorkMapView-module--workArea--+0HG3";
export var workAreas = "WorkMapView-module--workAreas--KGEm2";
export var workAreasInputs = "WorkMapView-module--workAreasInputs--4khTT";
export var workMapView = "WorkMapView-module--workMapView--iIPkH";